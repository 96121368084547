<template>
  <div class="home_container">
    <!-- 轮播图 -->
    <div class="swiper">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in swiperImgList" :key="index">
          <img style="width: 100%; height: 100%" :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 搜索框 -->
    <div class="search">
      <div class="inputBox">
        <el-button type="primary" class="searchBtn" @click="searchByKeyWord"
          >搜索</el-button
        >
        <el-input
          placeholder="请输入内容"
          v-model="input"
          clearable
          class="inputClass"
        >
        </el-input>
      </div>
    </div>
    <!-- 教会动态 + 经典推介 + 灵修动态 -->
    <div class="parts">
      <Cart-Part
        v-for="(item, index) in CartPartMessage"
        :key="index"
        :CurrentMessage="CartPartMessage[index]"
        class="CurrentMessage"
      />
    </div>
    <div class="relativeActivity">
      <div class="partsTime">
        <div class="TopNav">
          <div class="title">事工招募</div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-down-circle-fill"></use>
          </svg>
        </div>
        <div class="timeList">
          <div class="item" v-for="(item, index) in NeedMembers" :key="index">
            <div class="itemTitle">{{ item.position }}</div>
            <div class="item">{{ item.introduce }}</div>
            <br />
          </div>
        </div>
      </div>
      <div class="characteristicMinistry">
        <div class="TopNav">
          <div class="title">特色事工</div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-down-circle-fill"></use>
          </svg>
        </div>
        <div class="swiper">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item
              v-for="(item, index) in footerSwiperImgList"
              :key="index"
            >
              <img :src="item" alt="" style="width: 100%; height: 100%" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartPart from "../components/CardPart.vue";
export default {
  data() {
    return {
      input: "", //搜索框内容
      CartPartMessage: [
        {
          parentId: 1,
          title: "教会动态",
          pic: require("../assets/image/first.jpeg"),
          MessageList: [],
        },
        {
          parentId: 2,
          title: "经典推介",
          pic: require("../assets/image/churchDynamic.jpeg"),
          MessageList: [],
        },
        {
          parentId: 3,
          title: "灵修动态",
          pic: require("../assets/image/faithEpuip.jpeg"),
          MessageList: [],
        },
      ],
      swiperImgList: [],
      footerSwiperImgList: [],
      NeedMembers: [
        {
          position: "‖ 会堂清洁",
          introduce: "保障教堂卫生清洁，每周六、周日下午散会后进行清扫",
        },
        {
          position: "‖ 颂歌诗班",
          introduce:
            "怀音乐侍奉心志，固定参与每周四晚排练及每月第二、四周主日晚献唱",
        },
        {
          position: "‖ 招待服侍",
          introduce: "协助维持会堂次序，统一着装，聚会须提前到场预备",
        },
        { position: "‖ 摄像摄影", introduce: "协助活动拍摄，影像资料留档" },
      ],
    };
  },
  mounted() {
    this.getInitData();
  },
  methods: {
    async getInitData() {
      this.getTopImageSwiper();
      this.getBottomImageSwiper();
      this.getChurchDynamic(); //获取教会动态
      this.getDeskMessage(); //获取经典推介
      this.getMorningLearningMessage(); //获取灵修信息
    },
    // 请求顶部轮播图
    async getTopImageSwiper() {
      const {
        data: { data: res },
      } = await this.$http.get("/api/page/index_image/list");
      let tmp = [];
      res.forEach((item) => {
        tmp.push(item.url);
      });
      this.swiperImgList = tmp;
    },
    // 请求底部轮播图
    async getBottomImageSwiper() {
      const {
        data: { data: res },
      } = await this.$http.get("/api/page/bottom_image/list");
      let tmp = [];
      res.forEach((item) => {
        tmp.push(item.url);
      });
      this.footerSwiperImgList = tmp;
    },
    // 获取教会动态
    async getChurchDynamic() {
      const {
        data: { data: res },
      } = await this.$http.get("/api/page/activity");
      console.log("res");
      this.CartPartMessage[0].MessageList = res.records;
    },
    // equip-》灵修
    // activity-》jh动态
    // preview-》讲台
    async getMorningLearningMessage() {
      const {
        data: { data: res },
      } = await this.$http.get("/api/page/equip");
      console.log("res");
      this.CartPartMessage[1].MessageList = res.records;
    },
    // 获取经典推介
    async getDeskMessage() {
      const {
        data: { data: res },
      } = await this.$http.get("/api/page/preview");
      console.log("res");
      this.CartPartMessage[2].MessageList = res.records;
    },
    async searchByKeyWord() {
      let keyword = this.input;
      // console.log("123")
      // const res = await this.$http.get(`/api/page/search?title=${this.input}`)
      // console.log("searchRes",res)
      this.$router.push({
        path: "/home/resultSearch",
        query: { keyword },
      });
    },
  },
  components: {
    CartPart,
  },
};
</script>
<style scoped lang="less">
@media (max-width: 768px) {
  // .home_container:nth-child(n + 2) {
  //   color: pink;
  // }
  .home_container {
    overflow: hidden;
    .swiper {
      height: 300px;
      width: 100vw;
    }
    .search {
      height: 46px;
      width: 100%;
      background-color: #eeeeee;
      display: flex;
      align-items: center;
      .inputBox {
        margin: 0 auto;
        width: 50vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        /deep/ .el-input__inner {
          height: 28px !important;
          line-height: 28px;
          border-radius: 0;
        }
        .searchBtn {
          height: 28px;
          text-align: center;
          line-height: 0;
          border-radius: 0;
          .inputClass {
            width: 218px;
            height: 28px;
          }
        }
      }
    }
    .parts {
      display: flex;
      // box-sizing: border-box;
      flex-wrap: wrap;
      width: 100%;
      .CurrentMessage {
        width: 140px;
      }
    }
    .relativeActivity {
      display: flex;
      margin: 0 auto;
      flex-direction: column;
      width: 100vw;
      margin-bottom: 20px;
      .partsTime {
        display: flex;
        height: 331px;
        width: 100vw;
        border: 1px solid #65aaea;
        flex-direction: column;
        .TopNav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100vw;
          height: 35px;
          background-color: #65aaea;
          .title {
            color: #fff;
            background: #1a72c3;
            line-height: 35px;
            height: 100%;
            width: 60px; //！
            font-size: 15px;
            padding: 0 15px;
          }
          .icon {
            font-size: 20px;
            margin-right: 10px;
          }
        }
        .timeList {
          width: 100%;
          overflow-y: scroll;
          height: calc(331px - 35px);
        }
      }
      .characteristicMinistry {
        display: flex;
        // height: 331px;
        width: 100vw;
        border: 1px solid #65aaea;
        flex-direction: column;
        .TopNav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100vw;
          height: 35px;
          background-color: #65aaea;
          .title {
            color: #fff;
            background: #1a72c3;
            line-height: 35px;
            height: 100%;
            width: 60px;
            font-size: 15px;
            padding: 0 15px;
          }
          .icon {
            font-size: 20px;
            margin-right: 10px;
          }
        }
        .swiper {
          width: 100%;
          height: 300px;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .home_container {
    overflow-x: hidden;
    overflow: hidden;
    .swiper {
      height: 300px;
      width: 100%;
    }
    .search {
      height: 46px;
      width: 100%;
      background-color: #eeeeee;
      display: flex;
      align-items: center;
      .inputBox {
        margin: 0 auto;
        // width: 1170px;
        display: flex;
        flex-direction: row-reverse;
        /deep/ .el-input__inner {
          width: 200px;
          height: 28px !important;
          line-height: 28px;
          border-radius: 0;
        }
        .searchBtn {
          height: 28px;
          text-align: center;
          line-height: 0;
          border-radius: 0;
        }
      }
    }
    .parts {
      display: flex;
      // justify-content: space-evenly;
      justify-content: center;
      // box-sizing: border-box;
      width: 750px;
      margin: 0 auto;
      height: 370px;
      margin-bottom: 20px;
      .CurrentMessage {
        width: 140px;
      }
    }
    .relativeActivity {
      display: flex;
      margin: 0 auto;
      width: 750px;
      margin-bottom: 20px;
      .partsTime {
        display: flex;
        height: 331px;
        width: 229px;
        border: 1px solid #65aaea;
        flex-direction: column;
        .TopNav {
          display: flex;
          // justify-content: space-between;
          justify-content: center;
          align-items: center;
          width: 229px;
          height: 35px;
          background-color: #65aaea;
          .title {
            color: #fff;
            background: #1a72c3;
            line-height: 35px;
            height: 100%;
            width: 60px;
            font-size: 15px;
            padding: 0 15px;
          }
          .icon {
            font-size: 20px;
            margin-right: 10px;
          }
        }
        .timeList {
          width: 100%;
          overflow-y: scroll;

          height: calc(331px - 35px);
        }
      }
      .characteristicMinistry {
        display: flex;
        height: 331px;
        width: calc(100% - 229px - 34px);
        border: 1px solid #65aaea;
        margin-left: 14px;
        flex-direction: column;
        .TopNav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 35px;
          background-color: #65aaea;
          .title {
            color: #fff;
            background: #1a72c3;
            line-height: 35px;
            height: 100%;
            width: 60px;
            font-size: 15px;
            padding: 0 15px;
          }
          .icon {
            font-size: 20px;
            margin-right: 10px;
          }
        }
        .swiper {
          width: 100%;
          height: calc(100% - 35px);
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .home_container {
    overflow-x: hidden;
    .swiper {
      height: 300px;
      width: 100%;
    }
    .search {
      height: 46px;
      width: 100%;
      background-color: #eeeeee;
      display: flex;
      align-items: center;
      .inputBox {
        margin: 0 auto;
        // width: 1170px;
        display: flex;
        flex-direction: row-reverse;
        /deep/ .el-input__inner {
          width: 200px;
          height: 28px !important;
          line-height: 28px;
          border-radius: 0;
        }
        .searchBtn {
          height: 28px;
          text-align: center;
          line-height: 0;
          border-radius: 0;
        }
      }
    }
    .parts {
      display: flex;
      // justify-content: space-evenly;
      justify-content: center;
      // box-sizing: border-box;
      width: 970px;
      margin: 0 auto;
      height: 370px;
      margin-bottom: 50px;
    }
    .relativeActivity {
      display: flex;
      margin: 0 auto;
      width: 970px;
      margin-bottom: 20px;
      .partsTime {
        display: flex;
        height: 331px;
        width: 303px;
        border: 1px solid #65aaea;
        flex-direction: column;
        .TopNav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 303px;
          height: 35px;
          background-color: #65aaea;
          .title {
            color: #fff;
            background: #1a72c3;
            line-height: 35px;
            height: 100%;
            width: 60px;
            font-size: 15px;
            padding: 0 15px;
          }
          .icon {
            font-size: 20px;
            margin-right: 10px;
          }
        }
        .timeList {
          width: 100%;
          overflow-y: scroll;

          height: calc(331px - 35px);
        }
      }
      .characteristicMinistry {
        display: flex;
        height: 331px;
        width: calc(100% - 303px - 34px);
        border: 1px solid #65aaea;
        margin-left: 14px;
        flex-direction: column;
        .TopNav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 35px;
          background-color: #65aaea;
          .title {
            color: #fff;
            background: #1a72c3;
            line-height: 35px;
            height: 100%;
            width: 60px;
            font-size: 15px;
            padding: 0 15px;
          }
          .icon {
            font-size: 20px;
            margin-right: 10px;
          }
        }
        .swiper {
          width: 100%;
          height: calc(100% - 35px);
        }
      }
    }
  }
}
// 当最小宽度在>1200时候 以下样式会触发
@media (min-width: 1200px) {
  .home_container {
    overflow-x: hidden;
    .swiper {
      height: 300px;
      width: 100%;
    }
    .search {
      height: 46px;
      width: 100%;
      background-color: #eeeeee;
      display: flex;
      align-items: center;
      .inputBox {
        margin: 0 auto;
        display: flex;
        flex-direction: row-reverse;
        /deep/ .el-input__inner {
          width: 200px;
          height: 28px !important;
          line-height: 28px;
          border-radius: 0;
        }
        .searchBtn {
          height: 28px;
          text-align: center;
          line-height: 0;
          border-radius: 0;
        }
      }
    }
    .parts {
      display: flex;
      justify-content: space-evenly;
      width: 1170px;
      margin: 0 auto;
      height: 370px;
      margin-bottom: 50px;
      .CurrentMessage {
        width: 140px;
      }
    }
    .relativeActivity {
      display: flex;
      margin: 0 auto;
      width: 1170px;
      margin-bottom: 20px;
      .partsTime {
        display: flex;
        height: 331px;
        width: 374px;
        border: 1px solid #65aaea;
        flex-direction: column;
        .TopNav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 374px;
          height: 35px;
          background-color: #65aaea;
          .title {
            color: #fff;
            background: #1a72c3;
            line-height: 35px;
            height: 100%;
            width: 60px;
            font-size: 15px;
            padding: 0 15px;
          }
          .icon {
            font-size: 20px;
            margin-right: 10px;
          }
        }
        .timeList {
          width: 100%;
          overflow-y: scroll;

          height: calc(331px - 35px);
        }
      }
      .characteristicMinistry {
        display: flex;
        height: 331px;
        width: calc(100% - 374px - 34px);
        border: 1px solid #65aaea;
        margin-left: 14px;
        flex-direction: column;
        .TopNav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 35px;
          background-color: #65aaea;
          .title {
            color: #fff;
            background: #1a72c3;
            line-height: 35px;
            height: 100%;
            width: 60px;
            font-size: 15px;
            padding: 0 15px;
          }
          .icon {
            font-size: 20px;
            margin-right: 10px;
          }
        }
        .swiper {
          width: 100%;
          height: calc(100% - 35px);
        }
      }
    }
  }
}
// 公共样式 不放进媒体查询
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
